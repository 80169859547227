<div class="event-modal">
    <div class="modal-header">
        <div class="event-header">
            <h2 class="event-title">{{ event.subject }}</h2>
            <button class="close-button" (click)="closeModal()">
                &#215;
            </button>
        </div>
    </div>

    <div class="modal-body">
        <div class="event-detail">
            <label>Start Time:</label>
            <span>{{ event.start | date:'M/d/yyyy h:mm a' }}</span>
        </div>

        <div class="event-detail">
            <label>End Time:</label>
            <span>{{ event.end | date:'M/d/yyyy h:mm a' }}</span>
        </div>

        <div class="event-detail">
            <label>Description:</label>
            <span [innerHTML]="sanitizedBody"></span>
        </div>

        <div class="event-detail">
            <label>All Day Event:</label>
            <span>{{ event.isAllDay ? 'Yes' : 'No' }}</span>
        </div>

        <div class="event-detail">
            <label>Recurrence:</label>
            <span>{{ event.recurrence ? 'Yes' : 'No' }}</span>
        </div>

        <hr class="divider"/>

        <div class="event-detail">
            <label>Organizer:</label>
            <span>{{ event.organizer }}</span>
        </div>

        <div class="event-detail">
            <label>Attendees:</label>
            <ul class="attendees-list">
                <li *ngFor="let attendee of requiredAttendeesList">{{ attendee }}</li>
            </ul>
        </div>

        <div class="event-detail">
            <label>Optional Attendees:</label>
            <ul class="attendees-list">
                <li *ngFor="let attendee of optionalAttendeesList">{{ attendee }}</li>
            </ul>
        </div>

        <hr class="divider"/>

        <div class="event-detail">
            <label>Location:</label>
            <span>{{ event.location }}</span>
        </div>

        <div class="event-detail">
            <label>Categories:</label>
            <span *ngFor="let category of event.categories" class="category">{{ category }}</span>
        </div>
    </div>

    <!-- <hr class="divider"/> -->

    <!-- <div class="modal-footer">
        <div class="event-footer">
            <label>Content Type:</label>
            <span>Event</span>
        </div>

        <div class="event-footer">
            <label>Version:</label>
            <span>2.0</span>
        </div>

        <div class="event-footer">
            <label>Created at:</label>
            <span>{{ event.createdDateTime | date:'M/d/yyyy h:mm a' }}</span>
        </div>

        <div class="event-footer">
            <label>Last modified at:</label>
            <span>{{ event.lastModifiedDateTime | date:'M/d/yyyy h:mm a' }}</span>
        </div>
    </div> -->
</div>
