import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MsalGuardConfiguration, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import AuthService from './content/auth/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export default class AppComponent implements OnInit {
  title = 'BBJPORTAL';

  projects;

  mainheight: number;

  isdisabledBtn = true;

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.mainheight = window.innerHeight;
  }

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    public activateRoute: ActivatedRoute,
    public authService: AuthService,
    public route: Router,
  ) {}

  ngOnInit(): void {
    this.mainheight = window.innerHeight;
  }
}
