import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ICalendarEvent } from '../calendar/calendar.component';

@Component({
  selector: 'app-event-detail-dialog',
  templateUrl: './event-detail-dialog.component.html',
  styleUrls: ['./event-detail-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class EventDetailDialogComponent {

  sanitizedBody: SafeHtml;
  requiredAttendeesList: string[];
  optionalAttendeesList: string[];

  constructor(
    public dialogRef: MatDialogRef<EventDetailDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public event: ICalendarEvent,
    private sanitizer: DomSanitizer
  ) {
    this.sanitizedBody = this.sanitizeHTMLBody(event.body);
    this.requiredAttendeesList = this.splitAttendees(event.requiredAttendees);
    this.optionalAttendeesList = this.splitAttendees(event.optionalAttendees);
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  sanitizeHTMLBody(body: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(body);
  }

  splitAttendees(attendees: string): string[] {
    return attendees ? attendees.split(';').filter(email => email.trim().length > 0) : [];
  }
}
