import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import axios from 'axios';
import * as moment from 'moment-timezone';
import environment from "src/environments/environment";

@Injectable({
  providedIn: 'root',
})
export default class CalendarService {

  getEvents(startDate: string, endDate: string): Observable<any> {

    const uri = environment.api.getCalendar.uri;

    const startTime = "00:00:00"; // mid night
    const endTime = "00:00:00"; // mid night
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone; // Get the user's computer/browser timezone
    const numericOffset = moment.tz(timezone).format('Z'); // Converts to `+05:00` format

    // console.log('timezone', numericOffset);
    const params =  `/starttime/${startDate}T${startTime}${numericOffset}` + 
                    `/endtime/${endDate}T${endTime}${numericOffset}`;
    // const params = `/starttime/2024-06-01T00:00:00/endtime/2025-05-30T00:00:00"`;

    const query =
      `?api-version=${environment.api.getCalendar.apiVersion}` +
      `&sp=${environment.api.getCalendar.sp}` +
      `&sv=${environment.api.getCalendar.sv}` +
      `&sig=${environment.api.getCalendar.sig}`;

    const graphEndpoint = `${uri}${params}${query}`;
    
    return new Observable(observer => {
      axios.get(graphEndpoint)
        .then(response => {
          observer.next(response.data);
          observer.complete();
        })
        .catch(error => {
          observer.error(error);
        });
    });
  }
}
