<ngx-spinner
  name="spinner"
  [fullScreen]="true"
  type="timer"
  size="medium"
  bdColor="#000000"
  template="<img src='assets/img/bbj-pmis-load-icon.svg'/> "
>
</ngx-spinner>

<router-outlet></router-outlet>
