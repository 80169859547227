<aecom-header
  *ngIf="authService.user"
  [productname]="productname"
  homeUrl=""
  [productsubtitle]="productsubtitle"
  [productmaintitle]="productmaintitle"
  [user]="authService.user"
  [(menuVisible)]="layoutService.showLeftNav"
  [logoutAction]="authService.logout.bind(authService)"
  [theme]="'dark'"
  [appLogo]="'appLogo'"
  [noMenu]="true"
  [showAppMenu]="true"
  [appItems]="appItems"
></aecom-header>

<div class="image" [ngClass]="authService.user ? 'showHeader' : 'hideHeader'">
  <div class="main-body">
    <div>
      <div [ngClass]="icon" class="icon-row"></div>
    </div>

    <div class="title-row">{{ title }}</div>

    <ng-container *ngFor="let content of contents">
      <div class="content-row">{{ content }}</div>
    </ng-container>

    <div *ngIf="showButtonAndContact" class="button-row">
      <aecom-button
        innerClass="btn blue btn-icon solid mx-auto"
        (click)="refreshPage()"
      >
        {{ buttonText }}
      </aecom-button>
    </div>
  </div>

  <div class="foot-row">
    <div class="foot-left-section">
      {{ copyRight }}
    </div>

    <div *ngIf="showButtonAndContact" class="foot-right-section">
      <div class="labelBox">Support</div>

      <div class="emailBox">
        <a href="mailto:support@bbjnyc.com">support@bbjnyc.com</a>
      </div>
    </div>
  </div>
</div>
