/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable security/detect-object-injection */
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable, forkJoin } from 'rxjs';
import { first, flatMap, map } from 'rxjs/operators';
import { NavigationService, TaskService } from '../api-generated/api/api';
import LoginUser from '../models/loginUser';
import NavService from '../services/nav.service';
import ContractService from '../services/contract.service';
import LoadingService from '../services/loading.service';
import AuthService from '../content/auth/auth.service';
import { INavigationRoot } from '../api-generated/model/iNavigationRoot';
import LocalTaskService from '../services/local-task.service';
import { ITaskTab } from '../api-generated';

interface ContractPermission {
  contractCode: string;
  permission: ContractGroupRole[];
}

interface ContractGroupRole {
  group: string;
  role: string;
}

@Injectable()
export default class LoginUserResolve implements Resolve<void> {
  loginAttempts = 0;

  constructor(
    private contractService: ContractService,
    private loadingService: LoadingService,
    private authService: AuthService,
    private baseService: NavService,
    private navService: NavigationService,
    public localTaskService: LocalTaskService,
    public taskService: TaskService,
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<void> {
    this.loadingService.start();
    return this.login() as Observable<void>;
  }

  private login() {
    return this.authService.login().pipe(
      flatMap((usr) => {
        return this.onLoggedIn(usr);
      }),
      first(),
    );
  }

  onLoggedIn(loginUser: LoginUser): Observable<unknown> {
    return forkJoin([
      this.navService.getAllMenus('portal'),
      this.taskService.getTasks(),
    ]).pipe(
      map(([root, tasks]: [INavigationRoot, ITaskTab[]]) => {
        this.baseService.setBroughData(root.boroughNavigation);
        this.baseService.setGlobalData(root.globalNavigation);
        this.baseService.setDataSubject(root.combinedBoroughs);
        this.localTaskService.setItems(tasks);
        if (root.globalNavigation.length) {
          this.baseService.setSelectedId(
            root.globalNavigation[0].globalModule.Guid,
          );
        } else if (root.boroughNavigation.length) {
          this.baseService.setSelectedId(
            root.boroughNavigation[0].boroughItem.Guid,
          );

          this.contractService.setSelected(
            root.boroughNavigation[0].projects[0],
          );
        }
        return null as unknown;
      }),
    );
  }
}
