/* eslint-disable import/no-extraneous-dependencies */
import { Inject, Injectable } from '@angular/core';
import {
  MsalBroadcastService,
  MsalService,
  MSAL_INSTANCE,
} from '@azure/msal-angular';
import {
  AccountInfo,
  InteractionRequiredAuthError,
  IPublicClientApplication,
  AuthenticationResult,
  EventType,
} from '@azure/msal-browser';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { UserService } from 'src/app/api-generated';
import LocalStorageService from 'src/app/services/local-storage.service';
import environment from 'src/environments/environment';
import LoginUser from '../../models/loginUser';

@Injectable({
  providedIn: 'root',
})
export default class AuthService {
  private loggedInSubject = new BehaviorSubject<boolean>(false);

  loggedIn$: Observable<boolean> = this.loggedInSubject.asObservable();

  private activePermissionSubject = new BehaviorSubject<number>(0);

  activePermission: Observable<number> = this.activePermissionSubject.asObservable();

  private currentUserSubject = new BehaviorSubject<LoginUser>(null);

  currentUser$: Observable<LoginUser> = this.currentUserSubject.asObservable();

  public user: LoginUser;

  public attemptedUrl: string;

  public token: string = null;

  private reloading = false;

  private loginInProcess = false;

  constructor(
    @Inject(MSAL_INSTANCE) private config: IPublicClientApplication,
    private msalService: MsalService,
    private localStorageService: LocalStorageService,
    private msalBroadcastService: MsalBroadcastService,
    private userService: UserService,
  ) {
    this.loggedInSubject.next(
      this.msalService.instance.getActiveAccount() !== null,
    );
    this.msalBroadcastService.msalSubject$.subscribe((val) => {
      if (
        val.eventType === EventType.SSO_SILENT_FAILURE ||
        val.eventType === EventType.ACQUIRE_TOKEN_FAILURE ||
        val.eventType === EventType.LOGIN_FAILURE
      ) {
        this.getToken(true);
      }
    });
  }

  login(): Observable<LoginUser> {
    if (!this.user) {
      return this.msalBroadcastService.inProgress$.pipe(
        mergeMap((message) => {
          const account = this.getAccount();
          if (account && !AuthService.isExpired(account)) {
            return this.setUser();
          }
          if (!this.loginInProcess) {
            this.getToken(true);
            return this.setUser();
          }
        }),
      );
    }

    return of(this.user);
  }

  private getAccount(): AccountInfo {
    return this.msalService.instance
      .getAllAccounts()
      .filter((a) => a.tenantId === environment.azureNew.tenantId)[0];
  }

  private static isExpired(account: AccountInfo, marginSeconds = 60): boolean {
    const expirationDateWithMargin = new Date(
      ((account.idTokenClaims as { exp: number }).exp - 60) * 1000,
    );
    const currentDate = new Date();
    return currentDate > expirationDateWithMargin;
  }

  private static getPermission(account: AccountInfo) {
    return ((account.idTokenClaims as unknown) as {
      roles: string[];
    }).roles;
  }

  private setUser(): Observable<LoginUser> {
    const account = this.getAccount();
    const permission = AuthService.getPermission(account);

    return this.getToken().pipe(
      switchMap((token) => {
        return this.userService.getUser(account.localAccountId);
      }),
      map((user) => {
        if (user) {
          let firstName = user.givenName;
          let lastName = user.surname;

          if (user.surname === null || user.givenName == null) {
            if (user.displayName.includes(',')) {
              const fullName = user.displayName.split(', ');

              [lastName, firstName] = fullName;
            } else {
              const email = user.mail.split('@')[0];
              const fullName = email.split('.');

              if (email.includes('.')) {
                [firstName, lastName] = fullName;
              } else {
                firstName = '??';
                lastName = '??';
              }
            }
          }

          this.user = new LoginUser(
            firstName,
            lastName,
            user.id,
            user.mail,
            user.companyName,
            (permission as string[]).join(),
          );
        } else {
          this.user = null;
        }

        return this.user;
      }),
    );
  }

  logout(): void {
    this.msalService.logoutPopup({
      mainWindowRedirectUri: '/sign-out',
    });
    this.clearUser();
  }

  private clearUser(): void {
    this.user = null;
    this.loggedInSubject.next(false);
    this.localStorageService.clearLocalStorage();
  }

  getToken(redirect?: boolean): Observable<string> {
    if (redirect && !this.reloading) {
      this.reloading = true;
    }

    const account = this.getAccount();

    const mapToken = (authResult: AuthenticationResult) => {
      this.token = authResult ? authResult.accessToken : null;

      return (this.token as unknown) as string;
    };

    const cleanLogin = (error: Error | null) => {
      if (error instanceof InteractionRequiredAuthError || error === null) {
        this.clearUser();
        this.loginInProcess = true;
        return this.msalService
          .loginRedirect({
            scopes: [environment.azureNew.scope],
          })
          .pipe(
            mergeMap(() => {
              this.loginInProcess = false;
              return this.msalService
                .acquireTokenSilent({
                  scopes: [environment.azureNew.scope],
                  account: this.getAccount(),
                })
                .pipe(map(mapToken));
            }),
          );
      }
      if (error instanceof InteractionRequiredAuthError) {
        console.error('An error occurred. Please try again.', error.message);
      }
      return null;
    };

    if (account && !AuthService.isExpired(account)) {
      const tokenUpdate = this.msalService.acquireTokenSilent({
        scopes: [environment.azureNew.scope],
        account,
      });

      return tokenUpdate.pipe(map(mapToken), catchError(cleanLogin));
    }
    return cleanLogin(null);
  }
}
