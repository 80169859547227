import { IMenuItem, LayoutService } from '@aecom/core';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import AuthService from 'src/app/content/auth/auth.service';
import LoadingService from 'src/app/services/loading.service';
import RouteParams from 'src/app/shared/route-params';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
})
export default class ErrorComponent implements OnInit {
  icon: string;

  title: string;

  contents: string[];

  supportEmail: string;

  buttonText: string;

  copyRight: string;

  showButtonAndContact: boolean;

  productname = 'Portal';

  productsubtitle = '';

  productmaintitle = 'NYC BBJ PROGRAM';

  appItems: IMenuItem[] = [
    {
      name: 'Support',
      icon: 'icon-action_app_support',
      id: 'support',
      externalurl:
        'https://apps.powerapps.com/play/c04c6371-a3a6-4b25-a94c-9fe7fd35a16b?tenantId=bfb413d6-9a23-4cd3-a58d-e141aaf2948a',
    },
  ];

  constructor(
    private router: Router,
    public authService: AuthService,
    public layoutService: LayoutService,
    private activatedRoute: ActivatedRoute,
    private loadingService: LoadingService,
  ) {}

  ngOnInit(): void {
    const errorCode = this.activatedRoute.snapshot.params[
      RouteParams.ErrorCode
    ];

    this.supportEmail = 'support@bbjnyc.com';
    this.copyRight = '© NYC BBJ PROGRAM';
    this.showButtonAndContact = true;

    switch (errorCode) {
      case '400':
        this.icon = 'icon icon-action_ban icon-blue icon-xl';
        this.title = '400 - Bad request';
        this.contents = [
          'This page isn’t working at the moment.',
          'If the problem continues, contact the admin.',
        ];
        this.buttonText = 'REFRESH';
        break;
      case '403':
        this.icon = 'icon icon-basic_eye_closed icon-blue icon-xl';
        this.title = '403 - Forbidden';
        this.contents = ['You don’t have permission to access this page.'];
        this.buttonText = 'RETURN HOME';
        break;
      case '404':
        this.icon = 'icon icon-basic_magnifier icon-blue icon-xl';
        this.title = '404 - Not Found';
        this.contents = [
          'The page you are looking for might have been removed,',
          'had its name changed or is temporaily unavailale.',
        ];
        this.buttonText = 'RETURN HOME';
        break;
      case '500':
        this.icon = 'icon icon-action_ban icon-blue icon-xl';
        this.title = '500 - Internal Server Error';
        this.contents = ['Currently unable to handle this request.'];
        this.buttonText = 'REFRESH';
        break;
      case '502':
        this.icon = 'icon icon-action_ban icon-blue icon-xl';
        this.title = '502 - Bad Gateway';
        this.contents = [
          'The server encountered a temporary error and could not complete your request.',
        ];
        this.buttonText = 'REFRESH';
        break;
      case '503':
        this.icon = 'icon icon-action_ban icon-blue icon-xl';
        this.title = '503 - Service Unavailable';
        this.contents = [
          'The service you requested is not available at this time.',
        ];
        this.buttonText = 'REFRESH';
        break;
      // default:
      //   this.icon = 'icon icon-action_ban icon-blue icon-xl';
      //   this.title = '500 - Internal Server Error';
      //   this.contents = ['Currently unable to handle this request.'];
      //   this.buttonText = 'REFRESH';
      default:
        this.icon = 'icon icon-action_send icon-blue icon-xl';
        this.title = 'COMING SOON';
        this.contents = [
          'We are due to launch our website very soon, so please stay tuned!',
        ];
        this.buttonText = 'RETURN HOME';
    }

    this.loadingService.stop();
  }

  refreshPage(): void {
    this.router.navigate(['/']);
  }
}
