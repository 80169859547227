import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import AuthService from '../auth/auth.service';

@Component({
  selector: 'app-singout',
  templateUrl: './singout.component.html',
  styleUrls: ['./singout.component.scss'],
})
export default class SingoutComponent implements OnInit {
  constructor(
    public authService: AuthService,
    public router: Router,
    private activeRoute: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    if (this.activeRoute.routeConfig.path === 'logout') {
      this.authService.logout();
    }
  }

  login(): void {
    this.router.navigate(['/']);
  }
}
