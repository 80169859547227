/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable security/detect-non-literal-fs-filename */
/* Above rule give false positive, as fs is not used in this file */
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, Subscription } from 'rxjs';
import {
  GlobalNavigation,
  BoroughNavigation,
  ProjectNavigation,
  ApplicationNavigation,
  Borough,
  GlobalModule,
  TPContract,
} from 'src/app/api-generated';
import ContactList from 'src/app/models/contactList';
import NavService from 'src/app/services/nav.service';
import ContractService from 'src/app/services/contract.service';
import LoadingService from 'src/app/services/loading.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import environment from 'src/environments/environment';
import AuthService from '../auth/auth.service';

@Component({
  selector: 'app-portal',
  templateUrl: './portal.component.html',
  styleUrls: ['./portal.component.scss'],
})
export default class PortalComponent implements OnInit {
  boroughList: BoroughNavigation[];

  globalList: GlobalNavigation[];

  title = 'NYC BBJ PROGRAM';

  showProjectDropDown = false;

  selectedProjectName = '';

  projectList: ProjectNavigation[] = [];

  pddOpen = false;

  showApplications = false;

  applicationList: ApplicationNavigation[] = [];

  contactList: ContactList[] = [];

  private selectSubscription: Subscription;

  selectedUrl: SafeUrl;

  getBoroughIcon = PortalComponent.getBoroughIcon;

  getApplicationIcon = PortalComponent.getApplicationIcon;

  stage = environment.stage;

  // State to track which component to display
  displayCalendar = false;

  calendarApplication: any;

  constructor(
    public baseService: NavService,
    private contractService: ContractService,
    private loadingService: LoadingService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private sanitizer: DomSanitizer,
    public authService: AuthService,
  ) {}

  ngOnInit(): void {
    this.boroughList = this.baseService.getBroughData().filter((b) => {
      return b.projects.length;
    });

    this.boroughList.sort((a, b) => a.boroughItem.Order - b.boroughItem.Order);
    this.globalList = this.baseService.getGlobalData();
    this.globalList.sort((a, b) => a.globalModule.Order - b.globalModule.Order);
    const isDBAdmin = this.authService.user.Permissions.includes('_DB_User');
    if (!isDBAdmin) {
      this.globalList.push({
        globalModule: {
          Embed: false,
          UrlTemplate:
            'https://apps.powerapps.com/play/c04c6371-a3a6-4b25-a94c-9fe7fd35a16b?tenantId=bfb413d6-9a23-4cd3-a58d-e141aaf2948a',
          DDCAdminEmail: 'StuartCy@ddc.nyc.gov',
          DocControlEmail: 'dc@bbjnyc.com',
          Order: 9,
          Color: 'bbj_action_app_support.svg',
          Icon: 'bbj_action_app_support.svg',
          Code: 'Support',
          Name: 'Support',
          Guid: '',
        },
        applications: [],
      });
    }

    this.selectSubscription = combineLatest([
      this.baseService.selectedItem$,
      this.contractService.selectedItem$,
    ]).subscribe(([selectBorough, selectProject]) => {
      this.resetPageView(selectBorough, selectProject);
    });

    const onboardingModule = this.globalList.find(
      (b) => b.globalModule.Guid === 'f5960107-e029-11eb-a7b4-ace2d34b68ef',
    )?.globalModule;

    if (
      this.activeRoute.routeConfig.path === 'onboarding' &&
      onboardingModule !== undefined
    ) {
      this.boroughSelect(onboardingModule);
    }
    console.log('globalList:', this.globalList);

    this.calendarApplication = this.globalList.find(g => g.globalModule.Guid === 'f595fd2c-e029-11eb-a7b4-ace2d34b68ef')
                                ?.applications.find(a => a.applicationId === '8a013221-0baf-11ea-917a-027e1b1809fa');

    console.log('calendarApp', this.calendarApplication);
    this.loadingService.stop();
  }

  ngOnDestroy(): void {
    this.selectSubscription.unsubscribe();
  }

  resetPageView(borough: Borough, contract: TPContract): void {
    this.showProjectDropDown = false;
    this.showApplications = false;
    this.projectList = [];
    this.applicationList = [];
    this.selectedProjectName = '';
    this.pddOpen = false;
    this.contactList = [PortalComponent.initContactList()];

    const selectedGlobal = this.globalList.filter((b) => {
      return b.globalModule.Guid === borough?.Guid;
    });

    if (selectedGlobal.length) {
      this.applicationList = selectedGlobal[0].applications;
      this.showApplications = true;

      if (
        selectedGlobal[0].globalModule.DocControlEmail &&
        selectedGlobal[0].globalModule.DocControlEmail !== ''
      ) {
        this.contactList.push(
          new ContactList(
            'Doc Control',
            selectedGlobal[0].globalModule.DocControlEmail,
          ),
        );
      }

      // if (
      //   selectedGlobal[0].globalModule.DDCAdminEmail &&
      //   selectedGlobal[0].globalModule.DDCAdminEmail !== ''
      // ) {
      //   this.contactList.push(
      //     new ContactList(
      //       'DDC Admin',
      //       selectedGlobal[0].globalModule.DDCAdminEmail,
      //     ),
      //   );
      // }
    } else {
      const selectedBorough = this.boroughList.filter((b) => {
        return b.boroughItem.Guid === borough?.Guid;
      });

      if (selectedBorough.length) {
        this.showProjectDropDown = true;
        this.projectList = selectedBorough[0].projects;

        const selectedProject = this.projectList.filter((p) => {
          return p.contractItem.Guid === contract?.Guid;
        });

        if (selectedProject.length) {
          this.showApplications = true;
          this.applicationList = selectedProject[0].applications;
          this.selectedProjectName = selectedProject[0].contactName;

          if (
            selectedProject[0].docEmail &&
            selectedProject[0].docEmail !== ''
          ) {
            this.contactList.push(
              new ContactList('Doc Control', selectedProject[0].docEmail),
            );
          }

          // if (
          //   selectedProject[0].pmisEmail &&
          //   selectedProject[0].pmisEmail !== ''
          // ) {
          //   this.contactList.push(
          //     new ContactList('DDC Admin', selectedProject[0].pmisEmail),
          //   );
          // }
        }
      }

      // console.log('selectedBorough', selectedBorough);
    }
  }

  boroughSelect(borough: Borough | GlobalModule): void {
    const selectedGlobal = this.globalList.filter((b) => {
      return b.globalModule.Guid === borough?.Guid;
    });

    // console.log(JSON.stringify(selectedGlobal));
    if (
      selectedGlobal.length > 0 &&
      selectedGlobal[0].globalModule.UrlTemplate &&
      !selectedGlobal[0].globalModule.Embed
    ) {
      const url = selectedGlobal[0].globalModule.UrlTemplate;
      window.open(url, '_blank');
    } else {
      // If it's not an external link, reset the displayCalendar to false
      this.displayCalendar = false;

      this.baseService.setSelectedId(borough.Guid);
    }

    if (selectedGlobal.length) {
      const newSelectedUrl = this.makeUrlSafe(
        selectedGlobal[0].globalModule.UrlTemplate,
      );

      // eslint-disable-next-line eqeqeq
      if (!this.selectedUrl && this.selectedUrl != newSelectedUrl) {
        this.selectedUrl = newSelectedUrl;
      }

      this.contractService.setSelected(null);
    } else {
      const selectedBorough = this.boroughList.filter((b) => {
        return b.boroughItem.Guid === borough?.Guid;
      });

      if (selectedBorough.length && selectedBorough[0].projects.length === 1) {
        this.contractService.setSelected(selectedBorough[0].projects[0]);
      } else {
        this.contractService.setSelected(null);
      }
    }
  }

  toggleProjectDropDown(): void {
    this.pddOpen = !this.pddOpen;
  }

  projectSelect(project: TPContract): void {
    this.contractService.setSelected(project);
  }

  static initContactList(): ContactList {
    return new ContactList('Support', 'support@bbjnyc.com');
  }

  static getBoroughIcon(borough: Borough | GlobalModule): string {
    return `assets/icons/${borough.Icon}`;
  }

  static getApplicationIcon(application: ApplicationNavigation): string {
    return `assets/icons/${application.logo}`;
  }

  reload(): void {
    this.router.navigated = false;
    this.router.navigate([this.router.url]);
  }

  private makeUrlSafe(url: string): SafeUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  // Toggle the display when an application is selected
  openApplication(event: MouseEvent, application: ApplicationNavigation): void {
    if (application.applicationId === '8a013221-0baf-11ea-917a-027e1b1809fa') {
      // Program-wide Calendar
      event.preventDefault(); // Prevent default link behavior
      this.displayCalendar = true;
    } else if (application.url) {
      // External URL provided
      window.open(application.url, '_blank');
      this.displayCalendar = false; 
    } else {
      // Handle the case where URL is not provided
      window.open('/error/comingSoon', '_blank');
      this.displayCalendar = false; 
    }
  }  

  goBackToApplicationList(): void {
    this.displayCalendar = false;
  }
  
}
