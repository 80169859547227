import { Injectable } from '@angular/core';
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import AuthService from '../content/auth/auth.service';
import LoadingService from '../services/loading.service';

@Injectable()
export default class ErrorResolve implements Resolve<void> {
  constructor(
    private authService: AuthService,
    private loadingService: LoadingService,
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<void> | Promise<void> | void {
    this.loadingService.start();
    return of(null);
    // const result = this.authService.login().pipe(
    //   map((loginUser) => {
    //     // console.log(loginUser);
    //     return null;
    //   }),
    //   catchError((error: HttpErrorResponse) => {
    //     return of(null);
    //   }),
    // );
    // return result;
  }
}
